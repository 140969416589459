<template>
  <section class="relative z-10">
    <div v-if="header" class="container hidden lg:flex justify-center pb-4">
      <h2
        :class="$route.name === 'contact' ? 'text-white pt-0' : 'pt-24'"
        class="text-center border-b-4 border-secondary lg:w-5/12 xl:w-4/12"
      >
        {{ header }}
      </h2>
    </div>
    <div class="container grid grid-cols-12 gap-5 block align-middle pt-24 px-0 lg:pt-0 lg:px-6">
      <div class="hidden lg:flex items-center lg:col-start-2 xxl:col-start-3">
        <button
          :disabled="activeIndex === 0"
          :class="{'opacity-25 cursor-auto hover:opacity-25': activeIndex === 0}"
          class="btn-round"
          @click="prev"
        >
          <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']"/>
        </button>
      </div>
      <div class="wrapper col-start-1 lg:col-start-3 xxl:col-start-4 lg:col-end-7 xxl:col-end-7">
        <figure class="active-testimonial z-30 lg:rounded-r-12p">
          <h3 class="text-heading pb-3" v-text="activeTestimonial.title"/>
          <p class="opacity-75">"{{ activeTestimonial.text }}"</p>
          <div class="text-heading pt-3 pb-4" v-text="activeTestimonial.organisation"/>
          <img v-if="activeTestimonial.image" :src="activeTestimonial.image" :alt="activeTestimonial.organisation">
        </figure>
        <div class="cover hidden lg:block border-l-10 border-secondary text-white relative z-40 lg:rounded-r-12p">
          <div class="front">
            <figure class="p-10 lg:rounded-r-12p">
              <h3 class="text-heading pb-3" v-text="activeTestimonial.title"/>
              <p class="opacity-75 whitespace-normal">"{{ activeTestimonial.text }}"</p>
              <div class="text-heading pt-3 pb-4" v-text="activeTestimonial.organisation"/>
              <img v-if="activeTestimonial.image" :src="activeTestimonial.image" :alt="activeTestimonial.organisation">
            </figure>
          </div>
          <div class="back lg:rounded-l-12p">
            <figure class="p-10 opacity-50 lg:rounded-r-12p">
              <h3 class="text-heading pb-3" v-text="inactiveTestimonial.title"/>
              <p class="opacity-75">"{{ inactiveTestimonial.text }}"</p>
              <div class="text-heading pt-3 pb-4" v-text="inactiveTestimonial.organisation"/>
              <img v-if="inactiveTestimonial.image" :src="inactiveTestimonial.image" :alt="inactiveTestimonial.organisation">
            </figure>
          </div>
        </div>
      </div>
      <div class="hidden lg:flex justify-end items-center col-start-11 xxl:col-start-10">
        <button
          :disabled="activeIndex === testimonials.length - 1"
          :class="{'opacity-25 cursor-auto hover:opacity-25': activeIndex === testimonials.length - 1}"
          class="btn-round"
          @click="next"
        >
          <font-awesome-icon :icon="['fas', 'long-arrow-alt-right']"/>
        </button>
      </div>
      <div class="lg:hidden flex w-full justify-between px-12 col-start-1 col-end-13">
        <button
          :disabled="activeIndex === 0"
          :class="{'opacity-25 cursor-auto hover:opacity-25': activeIndex === 0}"
          class="btn-round"
          @click="prev"
        >
          <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']"/>
        </button>
        <button
          :disabled="activeIndex === testimonials.length - 1"
          :class="{'opacity-25 cursor-auto hover:opacity-25': activeIndex === testimonials.length - 1}"
          class="btn-round"
          @click="next"
        >
          <font-awesome-icon :icon="['fas', 'long-arrow-alt-right']"/>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    header: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      activeIndex: 0,
      inactiveIndex: 0,
      wrapperElement: null,
    };
  },
  computed: {
    ...mapState({
      testimonials: state => state.testimonials,
      testimonialsCount: state => state.testimonialsCount,
    }),
    inactiveTestimonial() {
      return this.testimonials[this.inactiveIndex];
    },
    activeTestimonial() {
      return this.testimonials[this.activeIndex];
    },
  },
  mounted() {
    this.wrapperElement = document.getElementsByClassName('wrapper')[0];
  },
  methods: {
    next() {
      this.wrapperElement.classList.add('active-wrapper');
      if (this.activeIndex < this.testimonials.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }

      if (this.inactiveIndex < this.testimonials.length - 1) {
        this.inactiveIndex++;
      } else {
        this.inactiveIndex = 0;
      }
    },
    prev() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.testimonials.length - 1;
      }

      if (this.inactiveIndex > 0) {
        this.inactiveIndex--;
      } else {
        this.inactiveIndex = this.testimonials.length - 1;
      }

      if (this.activeIndex === 0) {
        this.wrapperElement.classList.remove('active-wrapper');
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  height: 420px
  position: relative
  left: 0
  perspective: 1000px
  @media(min-width: theme('screens.sm'))
    transform: translate(102%, 0)
    left: 3.5vw
  @media(min-width: theme('screens.md'))
    left: 0

.cover
  background: theme('colors.primary.dark')
  width: 100%
  height: 100%
  position: absolute
  left: 0
  transform: rotateY(0deg)
  transform-style: preserve-3d
  transform-origin: left
  transition: all .5s ease-in

.active-wrapper .cover
  transform: rotateY(-180deg)

figure
  margin: 0
  display: block
  position: absolute
  top: 0
  left: 0
  width: 100vw
  height: 100%
  backface-visibility: hidden
  @media(min-width: theme('screens.sm'))
    width: 66.5vw
  @media(min-width: theme('screens.lg'))
    width: 100%

div.front
  transform: rotateX(0deg)
  backface-visibility: hidden

div.back
  transform: rotateY(180deg) rotateX(0deg)
  backface-visibility: hidden
  position: relative
  background: url("~assets/img/bg-testimonials.svg") no-repeat left center
  height: 100%
  width: 100%
  &:before
    position: absolute
    content: ''
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: white
    border-top-left-radius: 12px
    opacity: .6
    z-index: 40
</style>
