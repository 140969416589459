// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/img/bg-testimonials.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wrapper[data-v-1282f9ec]{height:420px;position:relative;left:0;perspective:1000px}@media (min-width:640px){.wrapper[data-v-1282f9ec]{transform:translate(102%);left:3.5vw}}@media (min-width:769px){.wrapper[data-v-1282f9ec]{left:0}}.cover[data-v-1282f9ec]{background:#0a3592;width:100%;height:100%;position:absolute;left:0;transform:rotateY(0deg);transform-style:preserve-3d;transform-origin:left;transition:all .5s ease-in}.active-wrapper .cover[data-v-1282f9ec]{transform:rotateY(-180deg)}figure[data-v-1282f9ec]{margin:0;display:block;position:absolute;top:0;left:0;width:100vw;height:100%;-webkit-backface-visibility:hidden;backface-visibility:hidden}@media (min-width:640px){figure[data-v-1282f9ec]{width:66.5vw}}@media (min-width:1025px){figure[data-v-1282f9ec]{width:100%}}div.front[data-v-1282f9ec]{transform:rotateX(0deg)}div.back[data-v-1282f9ec],div.front[data-v-1282f9ec]{-webkit-backface-visibility:hidden;backface-visibility:hidden}div.back[data-v-1282f9ec]{transform:rotateY(180deg) rotateX(0deg);position:relative;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0;height:100%;width:100%}div.back[data-v-1282f9ec]:before{position:absolute;content:\"\";top:0;bottom:0;left:0;right:0;background:#fff;border-top-left-radius:12px;opacity:.6;z-index:40}", ""]);
// Exports
module.exports = exports;
