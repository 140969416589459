<template>
  <section
    :class="$route.name === 'contact' ? 'pt-32' : 'py-32'"
    class="header flex flex-col justify-center bg-bottom bg-cover bg-no-repeat lg:py-56 relative overflow-hidden"
  >
    <div class="container text-white grid grid-cols-1 lg:grid-cols-2 z-10">
      <div :class="contact ? 'pb-24' : 'row-start-2 lg:row-start-1 text-center md:text-left pb-16 lg:py-16'">
        <h1 :title="page.title">
          <img v-if="logo" :src="logo" alt="Datalead" class="hidden lg:block">
        </h1>
        <read-btn class="pt-6"/>
        <h1 v-if="title" class="text-white text-2xl md:text-4xl" v-text="title"/>
        <p
          :class="[
            {'font-semi text-lg': !contact},
            $route.name === 'contact' ? 'text-left pt-2' : 'text-center',
          ]"
          class="w-full lg:w-9/12 lg:text-left leading-relaxed lg:leading-loose"
          v-text="content"
        />
        <dynamic-form v-if="contact"/>
      </div>
      <div class="flex overflow-hidden">
        <client-only v-if="$route.name === 'index'">
          <lottie class="transform scale-125" :options="defaultOptions" @animCreated="handleAnimation"/>
        </client-only>
        <img v-else :src="imageUrl" alt="Datalead">
      </div>
    </div>
    <section class="md:hidden">
      <div class="absolute first-image">
        <img :src="require('~/assets/img/cloud.svg')" alt="" aria-hidden="true">
      </div>
      <div class="absolute second-image">
        <img :src="require('~/assets/img/cloud.svg')" alt="" aria-hidden="true">
      </div>
      <div class="absolute third-image">
        <img :src="require('~/assets/img/cloud.svg')" alt="" aria-hidden="true">
      </div>
    </section>
    <section class="hidden md:block">
      <div class="absolute first-animation">
        <img :src="require('~/assets/img/cloud.svg')" alt="" aria-hidden="true">
      </div>
      <div class="absolute second-animation">
        <img :src="require('~/assets/img/cloud.svg')" alt="" aria-hidden="true">
      </div>
      <div class="absolute third-animation">
        <img :src="require('~/assets/img/cloud.svg')" alt="" aria-hidden="true">
      </div>
    </section>
  </section>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue';
import DynamicForm from './DynamicForm';
import ReadBtn from '~/layouts/partials/ReadBtn';
import * as animationData from '~/assets/lottie/home.json';
import page from '~/plugins/mixins/page';

export default {
  components: {
    DynamicForm,
    ReadBtn,
    Lottie,
  },
  mixins: [page],
  props: {
    logo: {
      type: String,
      default: null,
    },
    contact: {
      type: Boolean,
      default: false,
    },
    imageUrl: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      defaultOptions: {animationData: animationData.default},
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },

    stop: function () {
      this.anim.stop();
    },

    play: function () {
      this.anim.play();
    },

    pause: function () {
      this.anim.pause();
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    },
  },
};
</script>

<style lang="sass" scoped>
.header
  background-image: url("~assets/img/header-home.svg")
</style>
